.page-claim-list {
  .button-container {
    display: flex;
    justify-content: space-between;
    .right-container {
      display: flex;
      .infor-container {
        display: flex;
        margin-bottom: 15px;
        .total-refund {
          margin-right: 32px;
          display: flex;
          align-items: center;
          .total-refund__icon {
            border-radius: 50%;
            background: rgba(#f3ba2f, 0.25);
            width: 36px;
            height: 36px;
            margin-right: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .total-refund__content {
            color: #d0d0d0;
            font-size: 28px;
            display: flex;
            .sub-text {
              white-space: nowrap;
              color: #f3ba2f;
              font-size: 15px;
              transform: translateY(5px);
              -webkit-transform: translateY(5px);
              -moz-transform: translateY(5px);
              -ms-transform: translateY(5px);
              -o-transform: translateY(5px);
            }
          }
        }
      }
      .button-group {
        .approve-button {
          margin-right: 16px;
        }
      }
    }
  }
  .Table {
    .Table__Body {
      .email {
        color: $keyword-highlight;
      }
      .experience-pack {
        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
        padding: 1px 5px;
        &.experience-pack--200 {
          color: #f4a261;
          background: rgba(#f4a261, 0.1);
          border: 1px solid #f4a261;
        }
        &.experience-pack--500 {
          color: #e9c46a;
          background: rgba(#e9c46a, 0.1);
          border: 1px solid #e9c46a;
        }
        &.experience-pack--1000 {
          color: #ccff33;
          background: rgba(#ccff33, 0.1);
          border: 1px solid #ccff33;
        }
        &.experience-pack--2000 {
          color: #9ef01a;
          background: rgba(#9ef01a, 0.1);
          border: 1px solid #9ef01a;
        }
        &.experience-pack--3000 {
          color: #38b000;
          background: rgba(#38b000, 0.1);
          border: 1px solid #38b000;
        }
        &.experience-pack--4000 {
          color: #008000;
          background: rgba(#008000, 0.1);
          border: 1px solid #008000;
        }
        &.experience-pack--5000 {
          color: #006400;
          background: rgba(#006400, 0.1);
          border: 1px solid #006400;
        }
        &.experience-pack--8000 {
          color: #004b23;
          background: rgba(#004b23, 0.1);
          border: 1px solid #004b23;
        }
        &.experience-pack--10000 {
          color: #7b2cbf;
          background: rgba(#7b2cbf, 0.1);
          border: 1px solid #7b2cbf;
        }
        &.experience-pack--15000 {
          color: #c55df6;
          background: rgba(#c55df6, 0.1);
          border: 1px solid #c55df6;
        }
        &.experience-pack--20000 {
          color: #f20089;
          background: rgba(#f20089, 0.1);
          border: 1px solid #f20089;
        }
      }
      .proof-photo-1 {
        width: 100%;
        max-height: 36px;
        object-fit: contain;
        cursor: pointer;
      }
      .proof-photo-2 {
        width: 100%;
        max-height: 36px;
        object-fit: contain;
        cursor: pointer;
      }
      .status {
        font-size: 14px;
        padding: 5px 8px;
        border-radius: 24px;
        &--pending {
          color: #ff9d00;
          background: rgba(255, 157, 0, 0.15);
          border: 1px solid rgba(255, 157, 0, 0.25);
          // border: 1px solid #ff9d00;
        }
        &--approved {
          color: #56ca00;
          background: rgba(86, 202, 0, 0.15);
          border: 1px solid rgba(86, 202, 0, 0.25);
          // border: 1px solid #56ca00;
        }
        &--reject {
          color: #ff4c51;
          background: rgba(255, 76, 81, 0.15);
          border: 1px solid rgba(255, 76, 81, 0.25);
          // border: 1px solid #ff4c51;
        }
      }
      .bot-status {
        font-size: 14px;
        padding: 5px 8px;
        border-radius: 24px;
        &--pending {
          color: #ff9d00;
          border: 1px solid #ff9d00;
        }
        &--success {
          color: #56ca00;
          border: 1px solid #56ca00;
        }
        &--failure {
          color: #ff4c51;
          border: 1px solid #ff4c51;
        }
      }
      .action {
        display: flex;
        align-items: center;
        &.action--orders {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: 0.25s ease-out;
          -webkit-transition: 0.25s ease-out;
          -moz-transition: 0.25s ease-out;
          -ms-transition: 0.25s ease-out;
          -o-transition: 0.25s ease-out;
          &:hover {
            transform: scale(1.2);
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
            -o-transform: scale(1.2);
          }
        }
      }
      .value .icon svg {
        height: 15px;
        padding-left: 5px;
        cursor: pointer;
        * {
          fill: $keyword-highlight;
        }
      }
    }
  }
  //[BEGIN] - component
  .orders-popup-compnt {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    .orders {
      background: #0f192f;
      border-radius: 8px;
      padding: 32px 64px;
      max-width: 900px;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      .indicator-close {
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 16px;
        width: 15px;
        height: 15px;
        padding: 12px;
        box-sizing: content-box;
        background: rgba(255, 255, 255, 0);
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
        -webkit-transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
        -moz-transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
        -ms-transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
        -o-transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
      }
      .title {
        font-size: 20px;
        color: #fff;
        font-weight: 700;
        margin-bottom: 32px;
        text-align: center;
      }
      .Table {
        .Table__Body {
          .status {
            padding: initial;
            &.status--win {
              color: #56ca00;
              background: rgba(#56ca00, 0.05);
            }
            &.status--lose {
              color: #ff4c51;
              background: rgba(#ff4c51, 0.05);
            }
            &.status--draw {
              color: #ff9d00;
              background: rgba(#ff9d00, 0.05);
            }
          }
        }
      }
    }
  }
  //[END] - component
}
