#electricBillFile {
    align-items: center;
}

.electric-bill-upload-file {
    .InputWraper {
        width: auto;
    }
    .InputWraper>.wraper>.input>input {
        height: 35px;
    }
    .electric-bill-upload-file__input {
        position: relative;
        text-align: center;
        svg {
            position: absolute;
            height: 15px;
            margin-top: 70px;
            margin-left: -30px;
        }
        .electric-bill-upload-file__input__file {
            .label {
                margin-top: 30px;
            }
        }
    }
    .electric-bill-upload-file__action {
        margin-top: 20px;
        text-align: center;
        button {
            padding: 0px 15px;
        }
        .electric-bill-upload-file__action__download {
            margin-left: 10px;
        }
    }
    .PopupWraper {
        .charge-bill-container {
            .charge-bill__title {
                margin-bottom: 18px;
            }
            .charge-bill__button-container {
                display: flex;
                justify-content: space-evenly;
                gap: 10;
                .button__cancel {
                    margin-left: 8px;
                }
            }
        }
    }
}