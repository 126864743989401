.tabWrapper {
  .tabLinks {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    border-bottom: 1px solid #ccc;

    li {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }

      a {
        display: block;
        padding: 8px 16px;
        text-decoration: none;
        color: #333;
        font-weight: 600;
        border: 1px solid #ccc;
        border-bottom: none;
        border-radius: 4px 4px 0 0;

        &:hover {
          background-color: #f5f5f5;
        }

        &.active {
          background-color: #fff;
          border-color: #ccc;
        }
      }
    }
  }

  .tabContent {
    padding: 16px;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
  }
}
