$navigatorWidth: 110px;
.UserWraper {
    padding: 0px 0px 0px $navigatorWidth;
}

.Navigator {
    // width: $navigatorWidth;
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    box-shadow: 0 1px 3px 0 rgba(54, 80, 138, 0.3);
    background: $secondary-background-color;
    border-right: 1px solid $primary-border-color;
    height: 100vh;
    user-select: none;
    // overflow-x: hidden;
    font-size: 14px;
    .menu {
        text-align: center;
        font-size: 16px;
        flex: 1;
        padding: 3%;
        overflow-y: scroll;
        a {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // flex-direction: column;
            // color: $grey;
            color: $grey;
            text-decoration: none;
            // padding: 1em 0.5em 1em 1em;
            padding: 10px 5px 10px 10px;
            // transition: 0.25s ease-out;
            .title {
                display: flex;
                align-items: center;
                justify-content: start;
            }
            svg {
                width: 16px;
                margin-right: 0.5em;
                * {
                    fill: $grey;
                }
            }
            &.active {
                color: #4680ff;
                background: #e4e4e7;
                border-radius: $borderRadius + 10px;
                svg * {
                    fill: #4680ff;
                }
                &:hover {
                    color: #4680ff;
                    svg * {
                        fill: #4680ff;
                    }
                }
            }
            &:hover {
                color: #4680ff;
                background: #e4e4e7;
                border-radius: $borderRadius + 10px;
                svg * {
                    fill: #4680ff;
                }
            }
        }
        a.logo {
            &:hover {
                background: transparent;
            }
        }
        a:not(div[class*='dropdown']) {
            margin-bottom: 15px;
        }
        div[class*='dropdown'] {
            margin-bottom: 15px;
            a {
                margin-bottom: 0px;
            }
        }
    }
    .dropdown {
        // &:not(:last-child) {
        //
        // }margin-bottom: 15px;
        .dropdown-menu {
            height: 0;
            visibility: hidden;
            margin: 0;
            padding: 0;
            a {
                padding: 10px 10px 10px 28px;
                margin-bottom: 0;
                text-decoration: none;
                display: block;
                background: none;
                .title {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                }
                &:hover {
                    transform: scale(1.1);
                    color: #4680ff;
                }
            }
            a[href*='/logout'] {
                color: #ff4c51;
                svg * {
                    fill: #ff4c51;
                }
            }
        }
        &.open .dropdown-menu {
            height: 100%;
            visibility: visible;
            transition: all 1s ease-in-out;
        }
    }
    img.logo {
        width: 80%;
        margin: 24px auto;
    }
    .btnLogout {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $grey;
        text-decoration: none;
        padding: 25px 0;
        cursor: pointer;
        svg {
            width: 16px;
            margin-bottom: 10px;
            * {
                fill: $grey;
            }
        }
        &:hover {
            color: #ff4c51;
            transform: scale(1.1);
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -o-transform: scale(1.1);
            svg * {
                fill: #ff4c51;
            }
        }
    }
    .toggle-button {
        visibility: hidden;
        position: absolute;
        top: 10px;
        right: 0;
        width: 40px;
        height: 40px;
        border: none;
        background-color: white;
        color: blue;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
        &:hover {
            transform: rotate(180deg);
        }
    }
    &.open {
        .toggle-button {
            transform: rotate(180deg);
        }
    }
    @media only screen and (max-width: 768px) {
        z-index: 99;
        width: 100%;
        position: fixed;
        top: 0;
        left: -100%;
        transition: all 0.3s ease-in-out;
        img.logo {
            width: 30%;
        }
        &.open {
            left: 0;
        }
        .toggle-button {
            visibility: unset;
            right: 10px;
        }
        .toggle-button_open {
            visibility: unset;
            right: -50px;
        }
    }
}