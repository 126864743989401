.InputSelectSortable {
  width: 100%;

  .InputSelect {
    width: 100%;
    border-bottom: 1px solid $borderColor;
  }
}

.SortableList {
  position: relative;
  z-index: 0;
  background-color: #f3f3f3;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  list-style: none;
  padding: 0;
  border-radius: $borderRadius;
  overflow: hidden;
}

.SortableItem {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 10px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $dark;
  font-weight: 500;

  .value {
    flex: 1;
    color: $dark;
  }

  .btnRemove {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    opacity: 0.8;
    border-radius: 50%;

    svg {
      height: 10px;

      * {
        fill: $danger;
      }
    }

    &:hover {
      opacity: 1;
      background: rgba($color: $danger, $alpha: 0.1);
    }
  }

  &:last-child {
    border-bottom: 0;
  }
}

.SortableHelper {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2), 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;
}

.DragHandle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: $inputHeight;
  padding: 0 10px;
  cursor: row-resize;

  svg {
    height: 15px;

    * {
      fill: $grey;
    }
  }
}
