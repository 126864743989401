.PopupWraper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.9);
  // width: 100%;
  // height: 100%;
  z-index: 800;
  // overflow-y: auto;
  // padding-bottom: 150px;
  .box {
    border: 1px solid rgba(70, 128, 255, 0.5);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    //max-width: calc(100% - 30px);
    background: $primary-background-color;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    .boxTitle {
      border-bottom: 1px solid rgba(70, 128, 255, 0.5);
      background: rgba(24, 41, 77, 1);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 15px;
      border-radius: 8px 8px 0 0;
      -webkit-border-radius: 8px 8px 0 0;
      -moz-border-radius: 8px 8px 0 0;
      -ms-border-radius: 8px 8px 0 0;
      -o-border-radius: 8px 8px 0 0;
      span {
        font-size: 1.3em;
      }
      .btnClose {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        opacity: 0.8;
        cursor: pointer;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        background: rgba(255, 255, 255, 0);
        transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
        -webkit-transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
        -moz-transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
        -ms-transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
        -o-transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
        svg {
          height: 15px;
          * {
            fill: $white;
          }
        }
        &:hover {
          background: rgba(255, 255, 255, 0.1);
          opacity: 1;
        }
      }
    }
    .content {
      padding: 32px 32px 20px;
    }
  }
}
