.checkbox-compnt {
  .checkbox-compnt__icon {
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    &:hover {
      &::before {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        opacity: 1;
        transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
        -moz-transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
        -ms-transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
        -o-transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: -8px;
      left: -8px;
      right: -8px;
      bottom: -8px;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      transform: scale(0);
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      -ms-transform: scale(0);
      -o-transform: scale(0);
      opacity: 0;
    }
    svg * {
      fill: #fff;
    }
  }
}
