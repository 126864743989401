.topup-points {
    background: #1d1d42;
    border: 1px solid #353570;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    padding: 40px 0;
    width: 100%;
    min-height: 725px;
    font-size: 16px;
    .topup-points__title {
        font-size: 20px;
        margin-left: 20px;
        font-weight: 1000;
        color: #fff;
        margin-bottom: 10px;
    }
    .topup-points__subtitle {
        font-size: 16px;
        margin-left: 24px;
        font-weight: 1000;
        color: #fff;
        margin-bottom: 20px;
    }
    .topup-points__wrap {
        margin-top: 20px;
        margin-left: 40px;
    }
    .topup-points__wrap * {
        padding: 5px;
    }
    .topup-points__elementFirst {
        font-weight: 900;
        color: hsl(0, 25%, 92%);
    }
}

.topup-points__text {
    color: #32528B;
    margin-bottom: 10px;
    margin-left: 20px;
    font-size: 12px;
    text-align: center;
}

@media (min-width: 992px) {
    .topup-points {
        .topup-points__elementFirst {
            flex: 0 0 12.666667%;
            max-width: 12.666667%;
        }
    }
}

@media (max-width: 820px) {
    .topup-points {
        .topup-points__wrapElement {
            margin-top: 15px;
        }
    }
}

@media (max-width: 767px) {
    .topup-points {
        .topup-points__title {
            font-size: 18px;
            margin-left: 0px;
            text-align: center;
        }
    }
}

.topup-select {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    width: 400px;
}

.topup-points__input {
    padding-left: 20px;
    padding-right: 20px;
    width: 430px;
}

@media (max-width: 767px) {
    .topup-points__input {
        width: 100%;
    }
    .topup-select {
        width: 100%;
    }
}

@media (min-width: 767px) {
    .topup-select {
        margin: 10;
        width: 50%;
    }
}