.transfer-to-user {
  background: $secondary-background-color;
  box-shadow: $primary-box-shadow;
  border: 1px solid $primary-border-color;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  @media (max-width: 576px) {
    .transfer-to-user__form {
      padding: 35px 45px !important;
      .transfer-to-user__form__input {
        margin-top: 40px !important;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .transfer-to-user__form {
      padding: 80px 80px !important;
    }
  }
  .transfer-to-user__form {
    padding: 128px 80px;
    .transfer-to-user__form__title {
      color: #fff;
      font-size: 32px;
      font-weight: 700;
      line-height: initial;
      text-align: center;
    }
    .transfer-to-user__form__input {
      margin-top: 80px;
      .spinner-grow {
        margin-bottom: 8px;
        display: inline-block;
        color: #f8f9fa;
        width: 16px;
        height: 16px;
        vertical-align: text-bottom;
        background-color: currentColor;
        border-radius: 50%;
        opacity: 0;
        animation: spinner-grow 0.75s linear infinite;
        -webkit-animation: spinner-grow 0.75s linear infinite;
        .sr-only {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          white-space: nowrap;
          border: 0;
        }
      }
      .phone-valid {
        margin-bottom: 8px;
      }
    }
    .transfer-to-user__form__action {
      margin-top: 32px;
    }
  }
  .transfer-to-user__banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .transfer-to-user__banner__img {
      width: 720px;
      user-select: none;
    }
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
