.transaction-detail {
  .transaction-detail-wrapper {
    .primary-info {
      font-size: 16px;
      color: #fff;
      background: $secondary-background-color;
      padding: 24px 56px;
      box-shadow: $primary-box-shadow;
      border: 1px solid $primary-border-color;
      border-radius: 12px;
      -webkit-border-radius: 12px;
      -moz-border-radius: 12px;
      -ms-border-radius: 12px;
      -o-border-radius: 12px;
      .primary-info__section {
        padding: 18px 0;
        &:not(:last-child) {
          border-bottom: 1px solid rgba($primary-border-color, 0.5);
        }
      }
      .primary-info__section__row {
        padding: 8px 0;
        .id-payment__label {
          font-size: 13px;
          color: rgba(255, 255, 255, 0.75);
        }
        .id-payment__value {
          font-size: 56px;
          font-weight: 700;
          line-height: initial;
        }
        .status {
          display: inline-flex;
          align-items: center;
          padding: 5px 8px;
          border-radius: 6px;
          background: rgba(#4fff00, 0.15);
          border: 1px solid rgba(#4fff00, 0.25);
          .status__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 6px;
            svg * {
              fill: #4fff00;
            }
          }
          .status__label {
            color: #4fff00;
          }
        }
        .timestamp {
          display: inline-flex;
          align-items: center;
          .timestamp__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 6px;
            svg {
              width: 16px;
              height: 16px;
              * {
                fill: #fff;
              }
            }
          }
          .timestamp__label {
          }
        }
        .phone-number {
          color: $keyword-highlight;
          &:not(.no-data):hover {
            cursor: pointer;
            filter: brightness(0.75);
            -webkit-filter: brightness(0.75);
          }
        }
        .description {
          background: rgba(255, 255, 255, 0.1);
          min-height: 8em;
          height: auto;
          padding: 12px;
          border-radius: 6px;
        }
      }
    }
  }
}
