.Breadcrumbs {
  ul {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #6c757d;
    margin-bottom: 16px;
    list-style: none;
  }

  .breadcrumb {
    color: #6c757d;
    text-decoration: none;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #007bff;
    }
  }

  .separator {
    margin: 0 8px;
    content: '>';
  }
}
