.PageFranchiseLevelSettingHistory {
  .Table {
    .Table__Body {
      .created-by {
        color: #4680ff;
      }
      .user-applied {
        color: #4680ff;
      }
      .level {
        max-width: 48px;
        width: 100%;
      }
      .value .icon svg {
        height: 15px;
        padding-left: 5px;
        cursor: pointer;
        * {
          fill: $keyword-highlight;
        }
      }
    }
  }
}
