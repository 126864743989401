.InputSelectTree {
  width: 100%;

  .dropdown {
    width: 100%;

    .dropdown-trigger {
      width: 100%;
      display: flex;
      border: none;
      color: $dark;
      font-size: 1em;

      ul.tag-list {
        width: 100%;

        .tag-item {
          .tag {
            display: inline-flex;
            align-items: center;
            background: none;
            border: none;

            .tag-remove {
              font-size: 1.1em;
              margin-left: 5px;
              cursor: pointer;
              color: $danger;
              opacity: 0.5;

              &:hover {
                opacity: 1;
              }
            }
          }

          .placeholder {
            color: $grey;
          }
        }
      }

      &:after {
        display: none;
      }
    }
  }

  &.hasValue {
    .placeholder {
      display: none !important;
    }
  }

  .radio-item {
    vertical-align: middle;
    margin: -5px 6px 0 0;
  }
}
