.InputTags {
  min-height: 41px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(70, 128, 255, 0.5);
  background: rgba(255, 255, 255, 0.2);

  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 9px;
  }

  .tag {
    width: auto;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 6px;
    margin: 0 6px 6px 0;
    background: $secondary-background-color;
    border: 1px solid $primary-border-color;

    .tag-title {
      margin-top: 3px;
    }

    .tag-close-icon {
      display: block;
      width: 16px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      font-size: 14px;
      margin-left: 8px;
      color: #0052cc;
      border-radius: 50%;
      background: #fff;
      cursor: pointer;
    }
  }

  input {
    width: 100%;
    color: #fff;
    border: none;
    height: 41px;
    font-size: 14px;
    padding: 4px 0 0 0;
    padding: 0 16px;
    border-radius: 10px;
    background: transparent;
    overflow: hidden;

    &:focus {
      border: none !important;
      box-shadow: none !important;
      outline: transparent;
    }
  }
}
