@import './ChartTrade';
@import './ResultsPanel';

.TradePannel {
  padding: 15px;
  border-radius: $borderRadius;
  background: $dark;

  .title {
    color: $white;
    font-size: 1.1em;
  }
}

.Mobile {
  .TradePannel {
    padding: 0px;
    background: transparent;
  }
}
