@keyframes upAnimate {
  0% {
    box-shadow: 0px 0px 2px $success;
  }
  50% {
    box-shadow: 0px 0px 15px $success;
  }
  0% {
    box-shadow: 0px 0px 2px $success;
  }
}

@keyframes downAnimate {
  0% {
    box-shadow: 0px 0px 2px $danger;
  }
  50% {
    box-shadow: 0px 0px 15px $danger;
  }
  0% {
    box-shadow: 0px 0px 2px $danger;
  }
}

.ControlTradeResults {
  padding: 15px;
  background: $dark;
  border-radius: $borderRadius;
  display: inline-block;
  width: 100%;

  .title {
    margin-bottom: 15px;
    color: $white;
    font-size: 1.2em;
  }

  .actions {
    display: flex;
    align-items: center;
    flex-direction: column;

    button.btn {
      width: 100%;
      height: 50px;
      font-size: 1.1em;
      font-weight: 600;
      border-radius: $borderRadius;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      cursor: pointer;
      transition: 0.2s ease;
      flex-direction: column;

      .label {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-left: 7px;
          height: 16px;

          * {
            fill: $white;
          }
        }
      }

      .message {
        font-size: 12px;
        margin-top: 5px;
        font-weight: 400;
      }

      &.up {
        background: $success;

        &:hover,
        &:active {
          box-shadow: 0px 0px 7px rgba($color: $success, $alpha: 1);
        }

        &.selected {
          background: $success;
          animation: upAnimate 1s infinite;
        }
      }

      &.down {
        background: $danger;

        &:hover,
        &:active {
          box-shadow: 0px 0px 7px rgba($color: $danger, $alpha: 1);
        }

        &.selected {
          background: $danger;
          animation: downAnimate 1s infinite;
        }
      }

      &:disabled {
        background: rgba($color: $white, $alpha: 0.1);
        cursor: default;

        &:hover {
          box-shadow: none;
        }
      }
    }

    .time {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 7px 15px;
      color: $white;
      border-radius: $borderRadius;
      min-width: max-content;

      .loading {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .label {
        font-size: 0.9em;
      }

      .value {
        font-size: 1.2em;
      }
    }
  }
}
