.add-points__user-info,
.add-points__form-add-point {
  background: #1d1d42;
  border: 1px solid #353570;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  padding: 20px 20px;
  width: 100%;
  min-width: 600px;
  font-size: 16px;

  .add-points__user-info--elemnet {
    div:first-child {
      font-weight: 1000;
      font-size: 18px;
    }
  }
}

.add-points__user-info {
  * {
    padding-top: 3.5px;
    padding-bottom: 3.5px;
  }
}

.add-points__form-add-point {
  .InputWraper.error > .wraper > .input {
    width: 300px !important;
  }

  input {
    width: 300px !important;
    height: 35px !important;
  }
  margin-top: 30px;
}

.add-points__button-add-point {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  * {
    margin: 0 5px;
  }
}

@media (max-width: 768px) {
  .add-points__user-info,
  .add-points__form-add-point {
    min-width: 0;
    width: 300px;
  }

  .add-points__form-add-point {
    .InputWraper {
      margin-top: 10px;
    }

    .InputWraper.error > .wraper > .input {
      width: 260px !important;
    }
  }

  .wrap-pop-up-add-point {
    .box {
      top: 45% !important;
      height: 600px;
      overflow-y: auto;
    }
  }
}
