.Tabs {
  display: flex;
  align-items: center;
  // border-bottom: 1px solid $borderColor;
  position: relative;
  z-index: 1;
  width: 100%;
  overflow-x: auto;

  .item {
    width: fit-content;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    font-weight: 500;
    font-size: 1.2em;
    color: $white;
    border-bottom: 2px rgba($color: $primary, $alpha: 0) solid;
    cursor: pointer;
    user-select: none;

    &:hover {
      color: $primary;
    }

    &.active {
      border-bottom: 2px $primary solid;
      color: $primary;
      cursor: default;
    }
  }
}

.Mobile {
  .Tabs {
    .item {
      height: 40px;
      padding: 0 15px;
      font-size: 1em;
      font-weight: 600;
    }
  }
}
