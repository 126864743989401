.InputPassword_ToggleVisible {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50px;
  top: 0px;
  right: 0;
  cursor: pointer;
  opacity: 0.75;

  svg {
    // height: 15px;

    * {
      fill: #fff;
    }
  }

  &:hover {
    opacity: 1;
  }
}

.InputPassword {
  padding-right: 50px !important;
  position: relative;
}
