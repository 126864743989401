.place-game-list {
  &.HideRangeTime {
    .InputDateTimeRange {
      display: none;
    }
  }
  .Table__FilterBox {
    .InputDateTimeRange {
      &.disabled {
        opacity: 0.25;
      }
    }
  }
  .Table {
    .Table__Body {
      .coin {
        display: flex;
        align-items: center;
        .coin-label {
          font-weight: 700;
        }
        .coin-avatar {
          width: 24px;
          height: 24px;
          margin-right: 16px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
        }
      }
      .email {
        color: $keyword-highlight;
      }
      .positive-value {
        background: rgba(86, 202, 0, 0.05);
        color: #56ca00;
      }
      .negative-value {
        background: rgba(255, 76, 81, 0.05);
        color: #ff4c51;
      }
      .value .icon svg {
        height: 15px;
        padding-left: 5px;
        cursor: pointer;
        * {
          fill: $keyword-highlight;
        }
      }
    }
  }
}
