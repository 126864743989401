.InterventionLimit {
  padding: 15px;
  background: $dark;
  border-radius: $borderRadius;
  display: inline-block;
  width: 100%;

  .title {
    margin-bottom: 15px;
    color: $white;
    font-size: 1.2em;
  }

  input {
    height: $inputHeight;
    background: none;
    outline: none;
    font-size: 1em;
    color: $white;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    margin-right: 5px;
    padding: 0 15px;
  }
}
