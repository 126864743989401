.CustomersList {
  &.HideRangeTime {
    .InputDateTimeRange {
      display: none;
    }
  }

  .Table__FilterBox {
    .InputDateTimeRange {
      &.disabled {
        opacity: 0.25;
      }
    }
  }

  .CustomersList__filter {
    margin-bottom: 30px;

    .CustomersList__filter--button {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      * {
        margin: 0 10px;
      }
    }
  }

  .CustomersList__table,
  .CustomersList__filter {
    background: #1d1d42;
    border: 1px solid #353570;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    padding: 40px 30px;
    width: 100%;

    .CustomersList__table--header {
      display: flex;
      justify-content: flex-end;

      .CustomersList__table--header--search {
        position: relative;
        margin-bottom: 30px;

        input {
          border-radius: 5px;
          text-indent: 26px;
          font-size: 1.85em;
        }

        input::placeholder {
          font-size: 17.5px;
        }

        svg {
          position: absolute;
          margin-left: 10px;
          margin-top: 15px;
          height: 15px;
        }
      }
    }
  }

  table {
    tbody {
      .avatar {
        display: flex;
        align-items: center;

        .avatar__image {
          margin-right: 16px;
          width: 56px;
          height: 56px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border: 3px solid;
          opacity: 1;
          transition: 0.25s;
          -webkit-transition: 0.25s;
          -moz-transition: 0.25s;
          -ms-transition: 0.25s;
          -o-transition: 0.25s;

          &:not(.image-error):hover {
            cursor: pointer;
            opacity: 0.75;
          }
        }
      }

      .email {
        color: $keyword-highlight;
      }

      .action {
        :hover {
          cursor: pointer;
        }

        display: flex;

        .action__edit {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
          cursor: pointer;
          transition: 0.25s ease-out;
          -webkit-transition: 0.25s ease-out;
          -moz-transition: 0.25s ease-out;
          -ms-transition: 0.25s ease-out;
          -o-transition: 0.25s ease-out;

          &:hover {
            transform: scale(1.2);
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
            -o-transform: scale(1.2);
          }

          svg * {
            fill: #8b72df;
          }
        }

        .action__lock {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ff4c51;
          padding: 5px 8px;
          border-radius: 4px;
          cursor: pointer;
          transition: 0.25s ease-out;
          -webkit-transition: 0.25s ease-out;
          -moz-transition: 0.25s ease-out;
          -ms-transition: 0.25s ease-out;
          -o-transition: 0.25s ease-out;

          &:hover {
            //transform: scale(1.2);
            //-webkit-transform: scale(1.2);
            //-moz-transform: scale(1.2);
            //-ms-transform: scale(1.2);
            //-o-transform: scale(1.2);
            filter: brightness(0.75);
            -webkit-filter: brightness(0.75);
          }

          .action__lock__icon {
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              width: 16px;
              height: 16px;

              * {
                fill: rgba(255, 255, 255, 0.5);
              }
            }
          }

          .action__lock__text {
            line-height: initial;
            margin-left: 6px;
            color: rgba(255, 255, 255, 0.75);
          }
        }

        .action__unlock {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #3aab1b;
          padding: 5px 8px;
          border-radius: 4px;
          cursor: pointer;
          transition: 0.25s ease-out;
          -webkit-transition: 0.25s ease-out;
          -moz-transition: 0.25s ease-out;
          -ms-transition: 0.25s ease-out;
          -o-transition: 0.25s ease-out;

          &:hover {
            // transform: scale(1.2);
            // -webkit-transform: scale(1.2);
            // -moz-transform: scale(1.2);
            // -ms-transform: scale(1.2);
            // -o-transform: scale(1.2);
            filter: brightness(0.75);
            -webkit-filter: brightness(0.75);
          }

          .action__unlock__icon {
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              width: 14px;
              height: 14px;

              * {
                fill: rgba(255, 255, 255, 0.75);
              }
            }
          }

          .action__unlock__text {
            line-height: initial;
            margin-left: 6px;
            color: rgba(255, 255, 255, 0.75);
          }
        }
      }
    }
  }

  .PopupWraper {
    .lock-user-container {
      .lock-user__title {
        margin-bottom: 8px;
      }

      .lock-user__button-container {
        display: flex;
        justify-content: space-evenly;

        .button__cancel {
          margin-left: 8px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .CustomersList__table {
    .Table {
      margin-top: 20px;
    }
  }

  .CustomersList__table--header {
    flex-direction: column;

    .CustomersList__table--header--search {
      margin-bottom: 0;
    }
  }

  .wrapButtonFilter {
    Button {
      padding: 0 45px !important;

      &:nth-child(n+2):not(:nth-child(1)) {
        margin-left: 0 !important;
        margin-top: 10px !important;
      }
    }
  }
}