.PageFranchiseTable {
  .Table {
    .Table__Body {
      tr {
        td {
          .user {
            // &:hover {
            //     text-decoration: underline;
            // }
            .user-clickable {
              cursor: pointer;
              color: #16b1ff;
              text-decoration: underline;
            }
          }
          .profit-volume-green {
            background: rgba(86, 202, 0, 0.05);
            color: #56ca00;
          }
          .profit-volume-red {
            background: rgba(255, 76, 81, 0.05);
            color: #ff4c51;
          }
          .level {
            max-width: 48px;
            width: 100%;
          }
        }
      }
    }
  }
}
