.transfer-list {
  .button-container {
    display: flex;
    justify-content: space-between;
    .button-group {
      .approve-button {
        margin-right: 16px;
      }
    }
    .infor-container {
      margin-bottom: 15px;
      display: flex;
      .user-participation {
        margin-right: 32px;
        display: flex;
        align-items: center;
        .user-participation__icon {
          border-radius: 50%;
          background: rgba(#199248, 0.25);
          width: 36px;
          height: 36px;
          margin-right: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .user-participation__content {
          color: #d0d0d0;
          font-size: 28px;
          display: flex;
          .sub-text {
            white-space: nowrap;
            color: #199248;
            font-size: 15px;
            transform: translateY(5px);
            -webkit-transform: translateY(5px);
            -moz-transform: translateY(5px);
            -ms-transform: translateY(5px);
            -o-transform: translateY(5px);
          }
        }
      }
      .total-transfer-amount {
        display: flex;
        align-items: center;
        .total-transfer-amount__icon {
          border-radius: 50%;
          background: rgba(#ffb500, 0.25);
          width: 36px;
          height: 36px;
          margin-right: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            * {
              fill: #ffb500;
            }
          }
        }
        .total-transfer-amount__content {
          color: #fff;
          font-size: 28px;
          font-weight: 700;
          display: flex;
          .sub-text {
            white-space: nowrap;
            color: #ffb500;
            font-size: 15px;
            transform: translateY(5px);
            -webkit-transform: translateY(5px);
            -moz-transform: translateY(5px);
            -ms-transform: translateY(5px);
            -o-transform: translateY(5px);
          }
        }
      }
    }
  }
  &.HideRangeTime {
    .InputDateTimeRange {
      display: none;
    }
  }
  .Table__FilterBox {
    .InputDateTimeRange {
      &.disabled {
        opacity: 0.25;
      }
    }
  }
  table {
    tbody {
      .user {
        display: flex;
        align-items: center;
        .user__avatar {
          display: flex;
          align-items: center;
          .user__avatar__image {
            margin-right: 16px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            border: 2px solid rgba(255, 255, 255, 0.25);
            opacity: 1;
            transition: 0.25s;
            -webkit-transition: 0.25s;
            -moz-transition: 0.25s;
            -ms-transition: 0.25s;
            -o-transition: 0.25s;
            &:not(.image-error):hover {
              cursor: pointer;
              opacity: 0.75;
            }
          }
        }
        .user__info {
          font-size: 13px;
          .user__info__phone {
            color: $keyword-highlight;
          }
        }
      }
    }
  }
}
