.transaction-list {
  .button-container {
    display: flex;
    justify-content: space-between;
  }
  .Table__FilterBox {
    .List {
      .admin-checkbox {
        display: flex;
        align-items: center;
        height: 39px;
        border-radius: 5px;
        // border: 1px solid rgba(70, 128, 255, 0.5);
        background: $table-background-color;
        padding: 0 8px;
        .label {
          color: #d3d3d3;
          font-size: 20px;
          margin-left: 8px;
          cursor: default;
        }
        .checkbox-compnt {
          .checkbox-compnt__icon {
            svg * {
              fill: #d3d3d3;
            }
          }
        }
      }
    }
  }
  .Table {
    .Table__Body {
      .user {
        display: flex;
        align-items: center;
        .user__avatar {
          display: flex;
          align-items: center;
          .user__avatar__image {
            margin-right: 16px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            border: 2px solid rgba(255, 255, 255, 0.25);
            opacity: 1;
            transition: 0.25s;
            -webkit-transition: 0.25s;
            -moz-transition: 0.25s;
            -ms-transition: 0.25s;
            -o-transition: 0.25s;
            &:not(.image-error):hover {
              cursor: pointer;
              opacity: 0.75;
            }
          }
        }
        .user__info {
          font-size: 13px;
          .user__info__phone {
            color: $keyword-highlight;
          }
        }
      }
      .action {
        display: flex;
        .action__edit {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
          cursor: pointer;
          transition: 0.25s ease-out;
          -webkit-transition: 0.25s ease-out;
          -moz-transition: 0.25s ease-out;
          -ms-transition: 0.25s ease-out;
          -o-transition: 0.25s ease-out;
          &:hover {
            transform: scale(1.2);
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
            -o-transform: scale(1.2);
          }
          svg * {
            fill: #8b72df;
          }
        }
      }
    }
  }
}
