.user-detail {
  .user-detail-wrapper {
    .primary-info__col {
      margin-bottom: 30px;
      .primary-info__balance {
        // margin-bottom: 30px;
        position: relative;
        background: $secondary-background-color;
        padding: 24px 32px;
        box-shadow: $primary-box-shadow;
        // border: 1px solid $primary-border-color;
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
        color: #fff;
        z-index: 1;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: url('/assets/images/card-cover-image9.png');
          background-size: cover;
          opacity: 0.75;
          z-index: -1;
          border-radius: 12px;
          -webkit-border-radius: 12px;
          -moz-border-radius: 12px;
          -ms-border-radius: 12px;
          -o-border-radius: 12px;
        }
        .info__balance__title {
          font-size: 16px;
        }
        .info__balance__info {
          margin-top: 4px;
          display: flex;
          justify-content: space-between;
          .info__balance__info__value {
            font-size: 32px;
            font-weight: 600;
            margin-bottom: 48px;
            line-height: initial;
          }
        }
        .info__balance__sub-info {
          .info__balance__sub-info__title {
            opacity: 0.6;
            font-size: 12px;
          }
        }
      }
      .primary-info__balance-chart {
        position: relative;
        width: 100%;
        height: 100%;
        background: $secondary-background-color;
        box-shadow: $primary-box-shadow;
        border: 1px solid $primary-border-color;
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
        .primary-info__balance-chart__title {
          position: absolute;
          margin: 24px 16px;
          padding: 0 8px;
          border-radius: 4px;
          color: #7dffad;
          font-size: 16px;
        }
        .primary-info__balance-chart__chart {
          width: 100%;
          height: 100%;
        }
      }
      .primary-info__general-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 48px;
        background: $secondary-background-color;
        border: 1px solid $primary-border-color;
        box-shadow: $primary-box-shadow;
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
        .primary-info__general-info__title {
          font-size: 28px;
          font-weight: 700;
          color: #fff;
        }
      }
      .primary-info__general-info__field {
        margin-top: 24px;

        .primary-info__general-info__field--button {
          margin-top: 24px;
          text-align: right;
        }
      }
    }
    .secondary-info__wrapper {
      margin-bottom: 30px;
      .secondary-info {
        height: 100%;
        background: $secondary-background-color;
        // border: 1px solid $primary-border-color;
        // box-shadow: $primary-box-shadow;
        // border-radius: 12px;
        .secondary-info__banner-section {
          height: 160px;
          background: $primary-border-color;
          border-radius: 12px 12px 0 0;
        }
        .secondary-info__avatar-section {
          display: flex;
          align-items: center;
          .secondary-info__avatar-section__avatar {
            .secondary-info__avatar-section__avatar__img {
              width: 160px;
              height: 160px;
              overflow: hidden;
              border: 8px solid $secondary-background-color;
              border-radius: 50%;
              background: #fff;
              opacity: 1;
              object-fit: cover;
              transition: 0.25s;
              &:not(.image-error):hover {
                cursor: pointer;
                opacity: 0.75;
              }
            }
          }
          .secondary-info__avatar-section__info {
            text-align: left;
            color: #fff;
            .secondary-info__avatar-section__info__name {
              font-size: 28px;
              font-weight: 700;
            }
            .secondary-info__avatar-section__info__phone {
              margin-top: 8px;
              font-size: 16px;
            }
          }
        }
        .secondary-info__lock-section {
          padding-bottom: 24px;
          .secondary-info__lock-section__lock-status {
            display: flex;
            justify-content: center;
            align-items: center;
            .secondary-info__lock-section__lock-status__status {
              font-size: 14px;
              padding: 5px 8px;
              border-radius: 24px;
              color: #ddd;
              background: rgba(#ddd, 0.15);
              border: 1px solid rgba(#ddd, 0.25);
              &.secondary-info__lock-section__lock-status__status--active {
                color: #4fff00;
                background: rgba(#4fff00, 0.15);
                border: 1px solid rgba(#4fff00, 0.25);
              }
              &.secondary-info__lock-section__lock-status__status--inactive {
                color: #ff4c51;
                background: rgba(#ff4c51, 0.15);
                border: 1px solid rgba(#ff4c51, 0.25);
              }
            }
          }
          .secondary-info__lock-section__lock-toggle {
            display: flex;
            justify-content: center;
            margin-top: 24px;
          }
        }
      }
    }
    .last-ten-transaction__wrapper {
      .last-ten-transaction {
        background: $secondary-background-color;
        border-radius: 12px;
        padding: 48px;
        border: 1px solid $primary-border-color;
        box-shadow: $primary-box-shadow;
        .Message.emptyData {
          padding-bottom: 0;
        }
        .last-ten-transaction__primary-title {
          font-size: 28px;
          font-weight: 700;
          color: #fff;
        }
        .last-ten-transaction__table {
          margin-top: 32px;
          border: none;
          box-shadow: none;
          padding: 0;
          .Table__Body {
            border-bottom: none;
            .user {
              display: flex;
              align-items: center;
              .user__avatar {
                display: flex;
                align-items: center;
                .user__avatar__image {
                  margin-right: 16px;
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%;
                  -ms-border-radius: 50%;
                  -o-border-radius: 50%;
                  border: 2px solid rgba(255, 255, 255, 0.25);
                  opacity: 1;
                  transition: 0.25s;
                  -webkit-transition: 0.25s;
                  -moz-transition: 0.25s;
                  -ms-transition: 0.25s;
                  -o-transition: 0.25s;
                  &:not(.image-error):hover {
                    cursor: pointer;
                    opacity: 0.75;
                  }
                }
              }
              .user__info {
                font-size: 13px;
                .user__info__phone {
                  color: $keyword-highlight;
                }
              }
            }
          }
        }
      }
    }
  }
}
