.InputSelect {
  width: 100%;
  border-radius: $inputRadius;
  border: 1px solid $input-border-color;
  min-width: 150px;

  &.InputSelect--is-disabled {
    opacity: 0.5;
  }

  .InputSelect__control {
    border: none !important;
    box-shadow: none !important;
    height: 37px;
    background: $input-background-color;
    color: $white !important;
  }

  .InputSelect__placeholder {
    color: $grey;
  }

  .InputSelect__indicator-separator {
    background: white !important;
  }

  .InputSelect__indicator {
    svg {
      * {
        fill: $grey;
      }
    }
  }

  .InputSelect__single-value {
    color: white !important;
  }

  .InputSelect__input {
    input {
      color: white !important;
    }
  }

  .InputSelect__menu {
    z-index: 10;
    border-radius: $inputRadius;
    border: 1px solid $input-border-color;
    background: $input-background-color;
    backdrop-filter: blur(240px);

    .InputSelect__menu-list {
      background: $input-background-color;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.75);
      border-radius: $inputRadius;

      .InputSelect__option {
        cursor: pointer;
        background: $input-background-color;
        color: white !important;
        overflow-x: hidden;
        transition: 0.5s;

        &:not(:last-child) {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            height: 1px;
            // width: 100%;
            border-bottom: 1px solid rgba(255, 255, 255, 0.08);
            transform: translateX(-50%);
          }
        }

        &:hover {
          background: $input-border-color;
        }
      }
    }
  }

  .InputSelect__option--is-selected {
    background: $primary;
    cursor: default;

    &:hover {
      background: $primary;
    }
  }
}

.wrap-button-create-user {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  * {
    margin: 0 5px;
  }
}

.wrap-form-create-user,
.wrap-form-update-user {
  .InputWraper {
    width: 150px;
  }
}

@media (max-width: 1024px) {
  .wrap-form-create-user,
  .wrap-form-update-user {
    display: flex;

    .InputWraper {
      width: 150px;
    }
  }
}

@media (max-width: 844px) {
  .wrap-form-create-user,
  .wrap-form-update-user {
    display: flex;
    flex-direction: column;

    .InputWraper {
      width: 600px;
    }
  }
}

@media (max-width: 768px) {
  .wrap-form-create-user,
  .wrap-form-update-user {
    display: flex;
    flex-direction: column;

    .InputWraper {
      width: 500px;
    }
  }
}

@media (max-width: 576px) {
  .UserList .UserList__table .UserList__table--header .UserList__table--header--search input {
    border-radius: $inputRadius;
    text-indent: 26px;
    max-width: 105%;
  }

  .wrap-form-create-user,
  .wrap-form-update-user {
    display: flex;
    flex-direction: column;

    .InputWraper {
      width: 300px;
    }
  }

  .wrap-pop-up-create-user {
    .box {
      top: 45% !important;
      height: 650px;
      overflow-y: auto;
    }
  }
}
