.PageHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  min-height: 56px;

  .title {
    font-size: 1.4em;
    display: flex;
    align-items: center;

    > svg {
      height: 22px;

      * {
        fill: $dark;
      }
    }

    .btnBack {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid $grey;
      margin-right: 10px;
      cursor: pointer;

      svg {
        height: 14px;

        * {
          fill: $grey;
        }
      }

      &:hover {
        border-color: $primary;

        svg * {
          fill: $primary;
        }
      }
    }
  }

  .actionBox {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: $borderRadius;
    border: 1px solid $borderColor;

    section {
      border-right: 1px solid $borderColor;
      padding-right: 10px;
      margin-right: 10px;

      &:last-child {
        padding-right: 0;
        margin-right: 0;
        border-right: 0;
      }
    }
  }
}
