.TableFilterInputNumber {
  display: flex;
  align-items: center;
  border-radius: $borderRadius;
  border: 1px solid $borderColor;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    svg {
      height: 15px;

      * {
        fill: $grey;
      }
    }
  }

  input {
    padding: 10px 15px;
    outline: none;
    border: none;
    font-size: 1em;
    color: $white;
    width: 100%;
    max-width: 100%;
    background: transparent;
  }
}
