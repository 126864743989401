// ============================ Required Core style ================================
$primary: #4680ff;
$success: #56ca00;
$info: #04cdf9;
$warning: #ff9d00;
$danger: #ff4c51;
$white: #fff;
$light: #f7f9fc;
$dark: #1a2138;
$offDark: #222b45;
$grey: #8f9bb3;
// Layout
$borderRadius: 10px;
$inputRadius: 10px;
$borderColor: rgba(#8f9bb3, 0.3);
$boxShadow: 0px 2px 3px
  rgba(
    $color: #000000,
    $alpha: 0.2,
  );
$inputHeight: 41px;
$buttonHeight: 38px;
$zIndexListSuggests: 102;
$zIndexTableLoadingData: 103;
$input-border-color: rgba(70, 128, 255, 0.5);
// $input-background-color: rgba(24, 41, 77, 0.5);
$input-background-color: rgba(255, 255, 255, 0.2);
$input-disabled-border-color: rgba(70, 128, 255, 0.1);
$input-disabled-text-color: rgba(255, 255, 255, 0.2);
$table-background-color: rgba(255, 255, 255, 0.2);
$keyword-highlight: #4680ff;
$primary-background-color: #141432;
$secondary-background-color: #1d1d42;
$primary-border-color: #353570;
$primary-box-shadow: 0 2px 14px 0 rgb(#000, 0.16);
//font
@font-face {
  font-family: 'Nunito Sans Black';
  font-style: normal;
  font-weight: 900;
  src: url('/assets/fonts/Nunito/Nunito-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito Sans Black';
  font-style: italic;
  font-weight: 900;
  src: url('/assets/fonts/Nunito/Nunito-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 700;
  font-style: normal;
  src: url('/assets/fonts/Nunito/Nunito-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito Sans Bold';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/Nunito/Nunito-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 800;
  font-style: normal;
  src: url('/assets/fonts/Nunito/Nunito-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito Sans ExtraBold';
  font-style: italic;
  font-weight: 800;
  src: url('/assets/fonts/Nunito/Nunito-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito Sans ExtraLight';
  font-style: normal;
  font-weight: 200;
  src: url('/assets/fonts/Nunito/Nunito-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito Sans ExtraLight';
  font-style: italic;
  font-weight: 200;
  src: url('/assets/fonts/Nunito/Nunito-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 400;
  font-style: italic;
  src: url('/assets/fonts/Nunito/Nunito-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 300;
  font-style: normal;
  src: url('/assets/fonts/Nunito/Nunito-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 300;
  font-style: italic;
  src: url('/assets/fonts/Nunito/Nunito-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 500;
  font-style: normal;
  src: url('/assets/fonts/Nunito/Nunito-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 500;
  font-style: italic;
  src: url('/assets/fonts/Nunito/Nunito-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 400;
  font-style: normal;
  src: url('/assets/fonts/Nunito/Nunito-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 600;
  font-style: normal;
  src: url('/assets/fonts/Nunito/Nunito-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 600;
  font-style: italic;
  src: url('/assets/fonts/Nunito/Nunito-SemiBoldItalic.ttf') format('truetype');
}

// Mixin Styles
@mixin borderBox {
  border: 1px solid $grey;
}

@mixin btnStyle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  font-weight: 400;
  outline: none;
  height: $buttonHeight;
  padding: 0 35px;
  border-radius: $borderRadius;
  cursor: pointer;
  outline: none;
  user-select: none;
  > svg {
    height: 16px;
    margin-right: 10px;
  }
  &:disabled {
    background: rgba($color: $grey, $alpha: 1);
    opacity: 0.2;
    cursor: not-allowed;
    color: $white;
    border-color: $grey;
    &:hover {
      background: rgba($color: $grey, $alpha: 1);
      opacity: 0.2;
      color: $white;
      border-color: $grey;
      box-shadow: none;
    }
  }
}

@mixin btnDarkColor($color) {
  @include btnStyle;
  background: $color;
  border: 1px solid $color;
  color: $white;
  transition: 0.25s ease;
  > svg * {
    fill: $white;
  }
  &:hover {
    box-shadow: 0px 0px 15px $color;
  }
}

@mixin btnOutlineColor($color) {
  @include btnStyle;
  background: none;
  border: 1px solid $color;
  color: $color;
  transition: 0.25s ease;
  opacity: 1;
  > svg * {
    fill: $color;
  }
  &:hover {
    opacity: 1;
    background: $color;
    color: $white;
    box-shadow: none;
    svg * {
      fill: $white;
    }
  }
  &.loading {
    border-color: rgba($color: $color, $alpha: 0);
    cursor: not-allowed;
    .iconLoading {
      svg circle {
        stroke: $color;
      }
    }
    &:hover {
      background: transparent !important;
    }
  }
}

// ============================ End Required Core style ============================
$zIndexInputArrayData__ChildForm: 500;
