.balance-management {
    background: #1d1d42;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.16);
    border: 1px solid #353570;
    border-radius: 12px;
    padding: 40px;
    &__action {
        &__transfer {
            margin-left: 10px;
        }
    }
    &__title {
        font-size: 28px;
        font-weight: 700;
        color: #fff;
        text-align: center;
    }
    &__form {
        margin-top: 2rem;
        &--btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .UserHead {
                background: rgba(143, 155, 179, 0.5);
                color: #fff;
                padding: 5px;
                border-radius: 10px;
            }
        }
        @media screen and (max-width: 768px) {
            &--btn {
                display: block;
                .Button.success {
                    width: 100%;
                }
                .UserHead {
                    margin-bottom: 1.5rem;
                    .profile {
                        justify-content: start;
                    }
                }
            }
        }
    }
}

.PopupWraper {
    .charge-bill-container {
        .charge-bill__title {
            margin-bottom: 18px;
        }
        .charge-bill__button-container {
            display: flex;
            justify-content: space-evenly;
            gap: 10;
            .button__cancel {
                margin-left: 8px;
            }
        }
    }
}