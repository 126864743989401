.PageAuth {
    background: $dark;
    width: 100%;
    min-height: 100vh;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    // overflow: auto;
    position: relative;
    .background {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 30px;
        max-width: 1000px;
        opacity: 0.02;
        z-index: 1;
    }
    .head {
        text-align: center;
    }
    form {
        position: relative;
        z-index: 10;
        max-width: 100%;
        width: 400px;
        user-select: none;
        img.logo {
            height: 60px;
            margin-bottom: 55px;
        }
        .title {
            color: $white;
            font-size: 1.5em;
            margin-bottom: 35px;
            font-weight: 300;
            line-height: 1.6em;
        }
        .Button {
            width: 100%;
            margin-top: 10px;
            margin-bottom: 50px;
        }
        .ctas {
            text-align: center;
            .item {
                font-weight: 300;
                margin-bottom: 15px;
                a {
                    margin-left: 5px;
                }
            }
        }
        input {
            background: transparent !important;
        }
    }
    &.register {
        form {
            width: 500px;
        }
    }
}

.Mobile {
    .PageAuth {
        padding: 30px;
        width: 100%;
        form {
            img.logo {
                height: 60px;
                margin-bottom: 25px;
            }
            .title {
                margin-bottom: 20px;
            }
        }
    }
}

.page-auth-login {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    height: 100%;
    // background: -webkit-linear-gradient(top left, #190650, #5E0A82);
    background-image: url('/assets/images/login-background.jpg');
    background-size: cover;
    // background-attachment: fixed;
    // background-position: center center;
    @media (min-width: 992px) {
        align-items: center;
    }
    .login-wrapper {
        padding: 80px 0;
        width: 100%;
        margin: 40px;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(80px);
        border-radius: 10px;
        @media (max-width: 768px) {
            padding: 0;
        }
        .login-form {
            border-left: 1px solid rgba(255, 255, 255, 0.1);
            width: 100%;
            padding: 40px;
            @media (min-width: 992px) {
                padding: 40px;
            }
            @media (min-width: 1200px) {
                padding: 100px;
            }
            .login-form__logo {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 62px;
                &::before {
                    content: '';
                    position: absolute;
                    height: 0%;
                    width: 50%;
                    top: 20%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    box-shadow: 0 0 112px 64px #fff;
                }
                .login-form__logo__img {
                    z-index: 1;
                    user-select: none;
                }
            }
            .login-form__actions {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                .login-form__actions__item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    transition: 0.25s;
                    -webkit-transition: 0.25s;
                    -moz-transition: 0.25s;
                    -ms-transition: 0.25s;
                    -o-transition: 0.25s;
                    &:hover {
                        color: #2a91ff;
                    }
                    svg {
                        width: 24px;
                        margin-right: 8px;
                    }
                }
                @media screen and (max-width: 576px) {
                    flex-direction: column;
                    button:first-child {
                        width: 100%;
                    }
                }
            }
            .InputWraper {
                .label {
                    margin-bottom: 12px;
                    margin-left: 8px;
                    font-size: 18px;
                    color: rgba(255, 255, 255, 0.5);
                }
                .wraper {
                    .input {
                        border: none;
                        input {
                            border: none;
                            padding: 0 16px;
                            background: rgba(255, 255, 255, 0.1);
                            box-shadow: none;
                            // border-radius: 44px;
                            height: 44px;
                            font-size: 18px;
                            transition: 0.25s;
                        }
                    }
                    .errorMessage {
                        font-size: 12px;
                        margin-left: 8px;
                    }
                }
                // &.error {
                //   .wraper {
                //     .input {
                //       border-radius: 44px !important;
                //     }
                //   }
                // }
                &:focus-within {
                    .wraper {
                        .input {
                            border: none !important;
                            input {
                                box-shadow: 0px 0px 4px rgba(255, 255, 255, 1);
                            }
                        }
                    }
                }
                &.disabled {
                    .wraper {
                        .input {
                            background: initial;
                            input {
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
            .Button {
                border: none;
                height: 44px;
                // border-radius: 44px !important;
                padding: 0 40px;
                font-size: 16px;
            }
            .Button.primary {
                background: rgba(255, 255, 255, 0.1);
                color: rgba(255, 255, 255, 0.75);
                &:not(.loading):hover {
                    background: #2a91ff;
                    color: #fff;
                }
            }
            .Button.primary-outline {
                padding-left: 0;
                padding-right: 0;
                text-decoration: underline;
                &:hover {
                    background: none;
                }
            }
        }
        .login-banner {
            display: flex;
            justify-content: center;
            .banner-image {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                margin-top: 40px;
                width: 85%;
                @media (min-width: 992px) {
                    margin-top: initial;
                }
                @media (min-width: 768px) {
                    width: 50%;
                }
                .banner-image__img {
                    width: 100%;
                    user-select: none;
                }
            }
        }
        .text-verify {
            margin-bottom: 10px;
            color: #2a91ff;
            // font-weight: bold;
            font-size: 18px;
        }
    }
}