.ChartTrade {
  width: 100%;
  position: relative;

  img.background {
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0.05;
    padding: 40px;
    position: absolute;
  }

  #chart,
  .chart-box {
    .apexcharts-text tspan {
      fill: $grey;
    }

    .apexcharts-menu-item {
      text-align: center;
      padding: 6px;
    }
  }
}

.Mobile {
  #chart {
    background: $dark;
    margin-bottom: 25px;
  }
}
