.change-password {
  background: $secondary-background-color;
  box-shadow: $primary-box-shadow;
  border: 1px solid $primary-border-color;
  border-radius: 12px;
  .change-password__form {
    padding: 48px;
    .change-password__form__title {
      color: #fff;
      font-size: 32px;
      font-weight: 700;
      line-height: initial;
      text-align: center;
    }
    .change-password__form__action {
      margin-top: 32px;
      text-align: right;
    }
  }
  .change-password__banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .change-password__banner__img {
      width: 600px;
      user-select: none;
    }
  }
}
