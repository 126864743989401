$time-loop: 3s;
$dot-size: 70%;
.four-dot-loading-compnt {
  position: relative;
  width: 100%;
  height: 100%;
  .dot {
    position: absolute;
    width: 50%;
    height: 50%;
    &.dot-1 {
      top: 0;
      left: 0;
      animation: loadingAnimtn1 $time-loop infinite;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: $dot-size;
        height: $dot-size;
        border-radius: 50%;
        background: #f63d3a;
      }
    }
    &.dot-2 {
      top: 0;
      right: 0;
      animation: loadingAnimtn2 $time-loop infinite;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: $dot-size;
        height: $dot-size;
        border-radius: 50%;
        background: #19a68c;
      }
    }
    &.dot-3 {
      bottom: 0;
      left: 0;
      animation: loadingAnimtn3 $time-loop infinite;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: $dot-size;
        height: $dot-size;
        border-radius: 50%;
        background: #fda543;
      }
    }
    &.dot-4 {
      bottom: 0;
      right: 0;
      animation: loadingAnimtn4 $time-loop infinite;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: $dot-size;
        height: $dot-size;
        border-radius: 50%;
        background: #193b48;
      }
    }
  }
  @keyframes loadingAnimtn1 {
    0% {
      top: 0;
      left: 0;
    }
    25% {
      top: 0;
      left: 50%;
    }
    50% {
      top: 50%;
      left: 50%;
    }
    75% {
      top: 50%;
      left: 0;
    }
    100% {
      top: 0;
      left: 0;
    }
  }

  @keyframes loadingAnimtn2 {
    0% {
      top: 0;
      right: 0;
    }
    25% {
      top: 50%;
      right: 0;
    }
    50% {
      top: 50%;
      right: 50%;
    }
    75% {
      top: 0;
      right: 50%;
    }
    100% {
      top: 0;
      right: 0;
    }
  }

  @keyframes loadingAnimtn3 {
    0% {
      bottom: 0;
      left: 0;
    }
    25% {
      bottom: 50%;
      left: 0;
    }
    50% {
      bottom: 50%;
      left: 50%;
    }
    75% {
      bottom: 0;
      left: 50%;
    }
    100% {
      bottom: 0;
      left: 0;
    }
  }

  @keyframes loadingAnimtn4 {
    0% {
      bottom: 0;
      right: 0;
    }
    25% {
      bottom: 0;
      right: 50%;
    }
    50% {
      bottom: 50%;
      right: 50%;
    }
    75% {
      bottom: 50%;
      right: 0;
    }
    100% {
      bottom: 0;
      right: 0;
    }
  }
}
