.InputTable {
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      font-weight: 500;
      color: $grey;
      text-align: left;
      padding: 10px 15px;
      user-select: none;
    }

    td {
      text-align: left;
      padding: 20px;
    }

    thead {
      tr {
        border-bottom: 1px solid $borderColor;
      }
    }

    tbody {
      td {
        .btnRemove {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          width: 30px;
          opacity: 0.5;
          transition: 0.2s ease;
          cursor: pointer;

          svg {
            height: 12px;

            * {
              fill: $danger;
            }
          }

          &:hover {
            border-radius: 50%;
            opacity: 1;
            background: rgba($color: $danger, $alpha: 0.2);
          }
        }

        input {
          height: 38px;
          padding: 7px;
          width: 100%;
          border-radius: $borderRadius;
          border: 1px solid $borderColor;
        }
      }
    }
  }

  .message {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    color: $grey;

    svg {
      height: 14px;
      margin-right: 7px;

      * {
        fill: $grey;
      }
    }
  }

  .ctas {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px;
    border-top: 1px solid $borderColor;

    .btnAddMore {
      @include btnOutlineColor($primary);
      display: inline-flex;
      height: 35px;
      border-radius: $borderRadius;
    }
  }
}
