.electric-bill-order-info__wrapInput,
.electric-bill-order-info__table {
  background: #1d1d42;
  border: 1px solid #353570;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  padding: 40px 0;
  width: 100%;

  .electric-bill-order-info__header {
    display: flex;
    align-items: center;
    padding: 0 30px 40px 30px;

    .electric-bill-order-info__header--right {
      flex: 10;
    }

    .electric-bill-order-info__header--left {
      flex: 1;
      position: relative;
      input {
        border-radius: 5px;
        text-indent: 30px;
        font-size: 1.4em;
      }
      input::placeholder {
        font-size: 14px;
      }
      svg {
        position: absolute;
        margin-left: -60px;
        margin-top: 8px;
        width: 150px;
        height: 15px;
      }
    }
  }
}

.electric-bill-order-info__wrapInput {
  margin: 0 auto;
}

.electric-bill-order-info__table {
  margin-top: 30px;
}

.action {
  width: 180px;
  display: flex;

  .action__edit {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    cursor: pointer;
    transition: 0.25s ease-out;
    -webkit-transition: 0.25s ease-out;
    -moz-transition: 0.25s ease-out;
    -ms-transition: 0.25s ease-out;
    -o-transition: 0.25s ease-out;

    &:hover {
      transform: scale(1.2);
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
    }

    svg * {
      fill: #8b72df;
    }
  }

  .action__show {
    .action__show__icon {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  .action__cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff4c51;
    padding: 5px 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.25s ease-out;
    -webkit-transition: 0.25s ease-out;
    -moz-transition: 0.25s ease-out;
    -ms-transition: 0.25s ease-out;
    -o-transition: 0.25s ease-out;

    &:hover {
      //transform: scale(1.2);
      //-webkit-transform: scale(1.2);
      //-moz-transform: scale(1.2);
      //-ms-transform: scale(1.2);
      //-o-transform: scale(1.2);
      filter: brightness(0.75);
      -webkit-filter: brightness(0.75);
    }

    .action__cancel__icon {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;

        * {
          fill: rgba(255, 255, 255, 0.5);
        }
      }
    }

    .action__cancel__text {
      line-height: initial;
      margin-left: 6px;
      color: rgba(255, 255, 255, 0.75);
    }
  }

  .action__unlock {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3aab1b;
    padding: 5px 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.25s ease-out;
    -webkit-transition: 0.25s ease-out;
    -moz-transition: 0.25s ease-out;
    -ms-transition: 0.25s ease-out;
    -o-transition: 0.25s ease-out;

    &:hover {
      // transform: scale(1.2);
      // -webkit-transform: scale(1.2);
      // -moz-transform: scale(1.2);
      // -ms-transform: scale(1.2);
      // -o-transform: scale(1.2);
      filter: brightness(0.75);
      -webkit-filter: brightness(0.75);
    }

    .action__unlock__icon {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 14px;
        height: 14px;

        * {
          fill: rgba(255, 255, 255, 0.75);
        }
      }
    }

    .action__unlock__text {
      line-height: initial;
      margin-left: 6px;
      color: rgba(255, 255, 255, 0.75);
    }
  }
}
