.page-game-orders-overview {
  &.HideRangeTime {
    .InputDateTimeRange {
      display: none;
    }
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    .infor-container {
      margin-bottom: 15px;
      display: flex;
      .total-orders {
        margin-right: 32px;
        display: flex;
        align-items: center;
        .total-orders__icon {
          border-radius: 50%;
          background: rgba(#0466c8, 0.25);
          width: 28px;
          height: 28px;
          margin-right: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (min-width: 1200px) {
            width: 36px;
            height: 36px;
          }
        }
        .total-orders__content {
          color: #d0d0d0;
          font-size: 22px;
          display: flex;
          @media (min-width: 1200px) {
            font-size: 28px;
          }
          .sub-text {
            white-space: nowrap;
            color: #0466c8;
            font-size: 13px;
            transform: translateY(5px);
            -webkit-transform: translateY(5px);
            -moz-transform: translateY(5px);
            -ms-transform: translateY(5px);
            -o-transform: translateY(5px);
            @media (min-width: 1200px) {
              font-size: 15px;
            }
          }
        }
      }
      .total-user {
        margin-right: 32px;
        display: flex;
        align-items: center;
        .total-user__icon {
          border-radius: 50%;
          background: rgba(#199248, 0.25);
          width: 28px;
          height: 28px;
          margin-right: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (min-width: 1200px) {
            width: 36px;
            height: 36px;
          }
        }
        .total-user__content {
          color: #d0d0d0;
          font-size: 22px;
          display: flex;
          @media (min-width: 1200px) {
            font-size: 28px;
          }
          .sub-text {
            white-space: nowrap;
            color: #199248;
            font-size: 13px;
            @media (min-width: 1200px) {
              font-size: 15px;
            }
            transform: translateY(5px);
            -webkit-transform: translateY(5px);
            -moz-transform: translateY(5px);
            -ms-transform: translateY(5px);
            -o-transform: translateY(5px);
          }
        }
      }
      .total-volume {
        display: flex;
        align-items: center;
        .total-volume__icon {
          border-radius: 50%;
          background: rgba(#f3ba2f, 0.25);
          width: 28px;
          height: 28px;
          margin-right: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (min-width: 1200px) {
            width: 36px;
            height: 36px;
          }
        }
        .total-volume__content {
          color: #d0d0d0;
          font-size: 22px;
          display: flex;
          @media (min-width: 1200px) {
            font-size: 28px;
          }
          .sub-text {
            white-space: nowrap;
            color: #f3ba2f;
            font-size: 13px;
            @media (min-width: 1200px) {
              font-size: 15px;
            }
            transform: translateY(5px);
            -webkit-transform: translateY(5px);
            -moz-transform: translateY(5px);
            -ms-transform: translateY(5px);
            -o-transform: translateY(5px);
          }
        }
      }
    }
  }
  .Table {
    .Table__Body {
      .email {
        color: $keyword-highlight;
      }
      .status {
        &.status--win {
          color: #56ca00;
          background: rgba(86, 202, 0, 0.05);
        }
        &.status--lose {
          color: #ff4c51;
          background: rgba(255, 76, 81, 0.05);
        }
        &.status--draw {
          color: #ff9d00;
          background: rgba(255, 157, 0, 0.05);
        }
        &.status--cancel {
          color: rgba(#fff, 0.25);
        }
      }
    }
  }
}
