.loading {
  position: relative;

  svg.spinner {
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    animation: none;
  }

  .loading__overlay {
    position: absolute;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .backdrop {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}
