.InputSelect {
  border-radius: 10px;
  width: 100%;

  .InputSelect__control {
    border-radius: 10px;
    border: none !important;
    box-shadow: none !important;
    height: $inputHeight;
  }

  .InputSelect__option {
    cursor: pointer;

    &:hover {
      background: rgba($color: $primary, $alpha: 0.2);
    }
  }

  .InputSelect__option--is-selected {
    background: $primary;
    cursor: default;

    &:hover {
      background: $primary;
    }
  }
}
