.Button__update--user {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  * {
    margin: 0 5px;
  }
}

@media (max-width: 576px) {
  .wrap-pop-up-update-user {
    .box {
      top: 45% !important;
      height: 650px;
      overflow-y: auto;
    }
  }
}
