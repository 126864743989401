body {
  overflow: auto;
}

.Table {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 32px;
  padding-right: 32px;
  background: $secondary-background-color;
  box-shadow: $primary-box-shadow;
  border: 1px solid $primary-border-color;
  border-radius: 10px;

  .Button > svg {
    margin: 0 !important;
  }

  .fetching {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $borderRadius;
    padding: 30px;
    z-index: 2;
    &.float {
      position: absolute;
      left: 0;
      background: rgba($color: $white, $alpha: 0.8);
    }
  }
  .Message.emptyData {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    color: #8f9bb3;
    font-size: 16px;
    svg {
      margin-right: 6px;
      * {
        fill: #8f9bb3;
      }
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    th {
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      text-align: left;
      padding: 15px;
      user-select: none;
    }
    td {
      text-align: left;
      padding: 16px 15px;
      font-weight: 400;
      color: $dark;
    }
    // ============================ Sort style ================================
    .enableSort {
      padding-right: 18px;
      position: relative;
      cursor: pointer;
      &:before,
      &:after {
        opacity: 1;
        border: 4px solid transparent;
        content: "";
        display: block;
        height: 0;
        right: 5px;
        top: 50%;
        position: absolute;
        width: 0;
      }
      &:before {
        border-bottom-color: $grey;
        margin-top: -9px;
      }
      &:after {
        border-top-color: $grey;
        margin-top: 1px;
      }
      &.increase {
        &:before,
        &:after {
          opacity: 1;
        }
        &:before {
          border-bottom-color: $white;
        }
      }
      &.descrease {
        &:before,
        &:after {
          opacity: 1;
        }
        &:after {
          border-top-color: $white;
        }
      }
    }
    // ============================ End Sort style ============================
    thead {
      white-space: nowrap;
      tr {
        border-bottom: 2px solid #353570;
      }
    }
    tbody.Table__Body {
      // background: $white;
      // box-shadow: $boxShadow;
      // border-radius: $borderRadius;
      border-bottom: 2px solid #353570;
      // ============================ Column border bottom style ================================
      tr {
        border-bottom: 1px solid rgba(#353570, 0.5);
        &:last-child {
          border-bottom: 0;
        }
      }
      // ============================ End Column border bottom style ============================
      // ============================ Body border radius style ================================
      tr:last-child {
        td:first-child {
          border-bottom-left-radius: $borderRadius;
          overflow: hidden;
        }
        td:last-child {
          border-bottom-right-radius: $borderRadius;
          overflow: hidden;
        }
      }
      tr:first-child {
        td:first-child {
          border-top-left-radius: $borderRadius;
          overflow: hidden;
        }
        td:last-child {
          border-top-right-radius: $borderRadius;
          overflow: hidden;
        }
      }
      // ============================ End Body border radius style ============================
    }
    // ============================ Filter style ================================
    tr {
      &.filter {
        td,
        th {
          padding-top: 0px;
          padding-bottom: 10px;
          .item {
            width: 100%;
          }
        }
      }
      .primary-column {
        .primary-column__wrapper {
          display: inline-flex;
          align-items: center;
          .primary-column__content {
            color: $keyword-highlight;
          }
          .primary-column__icon {
            display: flex;
            align-items: center;
            transform: translateY(-2px);
            -webkit-transform: translateY(-2px);
            -moz-transform: translateY(-2px);
            -ms-transform: translateY(-2px);
            -o-transform: translateY(-2px);
            svg {
              height: 15px;
              margin-left: 6px;
              &:hover {
                cursor: pointer;
              }
              * {
                fill: $keyword-highlight;
              }
            }
          }
        }
      }
      .status {
        font-size: 14px;
        padding: 5px 8px;
        border-radius: 24px;
        color: #ddd;
        background: rgba(#ddd, 0.15);
        border: 1px solid rgba(#ddd, 0.25);
        &.status--grey {
          color: #ddd;
          background: rgba(#ddd, 0.15);
          border: 1px solid rgba(#ddd, 0.25);
        }
        &.status--blue {
          color: #5a8dee;
          background: rgba(#5a8dee, 0.15);
          border: 1px solid rgba(#5a8dee, 0.25);
        }
        &.status--green {
          color: #4fff00;
          background: rgba(#4fff00, 0.15);
          border: 1px solid rgba(#4fff00, 0.25);
        }
        &.status--yellow {
          color: #ffad00;
          background: rgba(#ffad00, 0.15);
          border: 1px solid rgba(#ffad00, 0.25);
        }
        &.status--red {
          color: #ff4c51;
          background: rgba(#ff4c51, 0.15);
          border: 1px solid rgba(#ff4c51, 0.25);
        }
        &.status--cyan {
          color: #00cfdd;
          background: rgba(#00cfdd, 0.15);
          border: 1px solid rgba(#00cfdd, 0.25);
        }
        &.status--pink {
          color: #ec37fc;
          background: rgba(#ec37fc, 0.15);
          border: 1px solid rgba(#ec37fc, 0.25);
        }
      }
    }
    // ============================ End Filter style ============================
  }
  .Header {
    @media screen and (max-width: 768px) {
      width: max-content;
    }
  }

  .Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
  }
  .PaginationInfo {
    color: $grey;
  }
  .Pagination {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    .btnPagination {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      width: 35px;
      cursor: pointer;
      border-style: none;
      background: rgba(70, 128, 255, 0);
      outline: none;
      border-radius: 50%;
      transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
      svg {
        height: 12px;
        * {
          fill: $grey;
        }
      }
      &:hover {
        background: rgba(70, 128, 255, 0.2);
        // border: 1px solid $primary;
        // svg * {
        //     fill: $primary;
        // }
      }
      &:disabled {
        opacity: 0.3;
        cursor: default;
        &:hover {
          background: rgba(70, 128, 255, 0);
          // border-color: rgba($color: $primary, $alpha: 0);
          // svg * {
          //     fill: $grey;
          // }
        }
      }
    }
    .pageNumber {
      color: $grey;
      height: 30px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pageSize {
      padding: 3px 5px;
      outline: none;
      border: none;
      font-size: 1em;
      color: #fff;
      width: fit-content;
      max-width: 100%;
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(255, 255, 255, 0.2);
      box-shadow: inset 0 0 16px 2px rgba(0, 0, 0, 0.15);
      transition: 0.5s;

      option {
        color: #000;
        background: #f8f8f8; // Set the background color to light gray
        box-shadow: inset 0 0 16px 2px rgba(0, 0, 0, 0.15);
        transition: 0.5s;
      }
    }
  }
  .Message {
    padding-top: 40px;
    padding-bottom: 40px;
    height: 100px;
  }
  &.hide {
    display: none;
  }
}

.Table.scroll {
  box-shadow: $boxShadow;
  border-radius: $borderRadius;
  &.insideScroll {
    max-height: 500px;
    overflow: auto;
  }
  table {
    position: relative;
    thead.Table__Filter {
      tr {
        td,
        th {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
    thead.Table__Head {
      tr {
        th {
          position: sticky;
          top: 0;
          background: $primary-background-color;
          color: #adb7be;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
    tbody {
      box-shadow: none;
      // ============================ Body border radius style ================================
      tr:last-child {
        td:first-child {
          border-bottom-left-radius: 0 !important;
          overflow: hidden;
        }
        td:last-child {
          border-bottom-right-radius: 0 !important;
          overflow: hidden;
        }
      }
      tr:first-child {
        td:first-child {
          border-top-left-radius: 0 !important;
          overflow: hidden;
        }
        td:last-child {
          border-top-right-radius: 0 !important;
          overflow: hidden;
        }
      }
      // ============================ End Body border radius style ============================
    }
  }
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 4px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #e6e6e6;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #cccaca;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    &:hover {
      background: #0b1b3f;
    }
  }
}

.Table__SearchBox {
  margin-bottom: 10px;
  &.hasValue {
    .input {
      border-color: $primary !important;
    }
  }
  .input {
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: $borderRadius;
    border: 1px solid rgba($color: $borderColor, $alpha: 0);
    transition: border 0.2s ease;
    &:focus-within {
      border: 1px solid $borderColor;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      svg {
        height: 15px;
        * {
          fill: $grey;
        }
      }
    }
    input {
      outline: none;
      border: none;
      font-size: 1em;
      color: $dark;
      width: 400px;
      max-width: 100%;
      background: transparent;
      &::placeholder {
        color: $grey;
        font-weight: 300;
      }
    }
    .btnClear {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      cursor: pointer;
      opacity: 0.7;
      svg {
        height: 10px;
        * {
          fill: $grey;
        }
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  .suggests {
    margin-top: 15px;
    background: $white;
    box-shadow: $boxShadow;
    overflow: hidden;
    border-radius: $borderRadius;
    .item {
      display: flex;
      align-items: center;
      padding: 15px;
      cursor: pointer;
      border-bottom: 1px solid $borderColor;
      &.focused {
        background: rgba($color: $primary, $alpha: 0.05);
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  .Message {
    margin: 30px 0;
  }
}

.Table__FilterBox {
  margin-bottom: 10px;
  padding: 10px 15px;
  border: 1px solid $primary-border-color;
  border-radius: $borderRadius;
  // border: 1px solid $input-border-color;
  display: flex;
  flex-direction: column;
  // background: rgba(255, 255, 255, 0.2);
  background: $secondary-background-color;
  box-shadow: $primary-box-shadow;
  // background: $main-background-color;
  > .Table__FilterBox__Label {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    justify-content: space-between;
    .content {
      color: rgba(255, 255, 255, 0.5);
      font-weight: 500;
      display: flex;
      align-items: center;
      svg {
        height: 15px;
        max-width: 15px;
        margin-right: 5px;
        * {
          fill: $grey;
        }
      }
    }
    .iconToggle {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 5px;
        * {
          fill: $grey;
        }
      }
    }
  }
  &.openFilter {
    > .Table__FilterBox__Label {
      .content {
        color: $white;
        svg * {
          fill: $white;
        }
      }
      .iconToggle {
        transform-origin: center center;
        transform: rotate(180deg);
      }
    }
  }
  .List {
    margin-top: 10px;
    margin-bottom: -15px;
    .Item {
      display: inline-flex;
      flex-direction: column;
      margin-right: 15px;
      margin-bottom: 15px;
      > .label {
        margin-bottom: 5px;
        color: rgba(255, 255, 255, 0.5);
        font-size: 0.9em;
      }
    }
  }
}
