.WithdrawProgress {
  .Table {
    table tbody {
      .value .icon svg {
        height: 15px;
        padding-left: 5px;
        cursor: pointer;
        * {
          fill: $keyword-highlight;
        }
      }
    }
  }
}
