.InputArrayData {
  width: 100%;
  position: relative;

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      color: $grey;
      text-align: left;
      padding: 15px 20px;
      user-select: none;
      font-size: 0.9em;
      font-weight: 500;
      text-transform: capitalize;
    }

    td {
      text-align: left;
      padding: 20px;
    }

    thead {
      tr {
        border-bottom: 1px solid $borderColor;
      }
    }

    tbody {
      td {
        .btnRemove {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          width: 30px;
          opacity: 0.5;
          transition: 0.2s ease;
          cursor: pointer;

          svg {
            height: 12px;

            * {
              fill: $danger;
            }
          }

          &:hover {
            border-radius: 50%;
            opacity: 1;
            background: rgba($color: $danger, $alpha: 0.2);
          }
        }

        .btnEdit {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          width: 30px;
          opacity: 0.5;
          transition: 0.2s ease;
          cursor: pointer;
          margin-right: 5px;

          svg {
            width: 15px;

            * {
              fill: $info;
            }
          }

          &:hover {
            border-radius: 50%;
            opacity: 1;
            background: rgba($color: $info, $alpha: 0.2);
          }
        }
      }
    }
  }

  .message {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    color: $grey;

    svg {
      height: 14px;
      margin-right: 7px;

      * {
        fill: $grey;
      }
    }
  }

  .ctas {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px;
    border-top: 1px solid $borderColor;

    .btnAddMore {
      @include btnOutlineColor($primary);
      display: inline-flex;
      height: 35px;
      border-radius: $borderRadius;
    }
  }

  .tableLoading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: $white, $alpha: 0.9);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.InputArrayData__ChildForm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.8);
  padding-left: 150px;
  z-index: $zIndexInputArrayData__ChildForm;

  .wraper {
    position: relative;
    width: 100%;
    height: 100%;
    background: $white;
    display: flex;
    flex-direction: column;

    .head {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $borderColor;

      .title {
        font-size: 1.3em;
        color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          height: 18px;
          margin-right: 15px;
          cursor: pointer;

          * {
            fill: $primary;
          }
        }
      }

      .btnClose {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        cursor: pointer;
        user-select: none;
        opacity: 0.5;

        svg {
          height: 15px;

          * {
            fill: $dark;
          }
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .body {
      flex: 1;
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 20px;
      padding-bottom: 80px;
    }

    .foot {
      border-top: 1px solid $borderColor;
      width: 100%;
      padding: 15px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: $white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
