@media (max-width: 576px) {
  .wrapButtonFilter {
    Button {
      padding: 0 45px !important;

      &:nth-child(n+2):not(:nth-child(1)) {
        margin-left: 0 !important;
        margin-top: 10px !important;
      }
    }
  }
}