.supplier-create-page {
  background: #0f192f;
  border: 1px solid rgba(70, 128, 255, 0.25);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 32px 0;
  .supplier-create-page__content {
    padding: 32px 48px;
    .title {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 32px;
      color: #fff;
    }
    .form {
      .input-container {
        margin-bottom: 24px;
        .label {
          font-size: 14px;
          color: #8f9bb3;
          margin-bottom: 8px;
        }
        #thumnail {
          .input {
            transform: scale(0.75);
            -webkit-transform: scale(0.75);
            -moz-transform: scale(0.75);
            -ms-transform: scale(0.75);
            -o-transform: scale(0.75);
            transform-origin: bottom left;
          }
        }
      }
    }
  }
  .supplier-create-page__cover {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
