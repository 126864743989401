.InputImage {
  position: relative;
  height: 150px;
  width: 100%;
  border-radius: $borderRadius;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    max-height: 300px;
    object-fit: contain;
  }
  input {
    outline: none;
  }
  &.hasImage {
    min-height: unset;
    min-width: unset;
    background: rgba($color: $white, $alpha: 0.1);
    .mesage {
      opacity: 0;
    }
  }
  .mesage {
    opacity: 1;
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: $borderRadius;
    cursor: pointer;
    text-align: center;
    padding: 15px;
    flex-direction: column;
    font-weight: 300;
    svg {
      margin-bottom: 7px;
      width: 20px;
      * {
        fill: $grey;
      }
    }
  }
  &.isDragActive,
  &:hover {
    .mesage {
      opacity: 1;
      color: $white;
      background: rgba($color: $dark, $alpha: 0.8);
      svg {
        * {
          fill: $white;
        }
      }
    }
  }
}
