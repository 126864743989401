.InputWraper {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  position: relative;

  >.label {
    color: $dark;
    transition: 0.2s ease;
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.required {
    >.label {
      span::after {
        content: '*';
        color: $danger;
        margin-left: 5px;
      }
    }
  }

  >.description {
    font-size: 0.9em;
    line-height: 1.4em;
    color: rgba($color: $dark, $alpha: 0.5);
    margin-bottom: 10px;
  }

  >.wraper {
    >.input {
      //border: 1px solid rgba(70, 128, 255, 0.5);
      width: 100%;
      position: relative;
      display: flex;
      border-radius: $inputRadius;

      >input,
      .InputDateTime {
        height: 44px;
        color: #fff;
        background: rgba(255, 255, 255, 0.25);
        border: 1px solid rgba(255, 255, 255, 0.2);
        outline: none;
        border-radius: $inputRadius;
        font-size: 1em;
        font-weight: 400;
        padding: 0px 16px;
        width: 100%;
        box-shadow: inset 0 0 16px 2px rgb(0 0 0 / 15%);
        flex: 1 1;
        transition: 0.25s;

        &:-webkit-autofill {
          font-size: 1em;
        }

        &:active {
          border: none;
        }
      }

      >textarea {
        background-color: rgba(255, 255, 255, 0.1);
        color: #fff;
        border: none;
        outline: none;
        border-radius: $inputRadius;
        font-size: 1em;
        font-weight: 400;
        padding: 10px;
        width: 100%;
        flex: 1;
        // min-height: $inputHeight * 2;
        min-height: $inputHeight;
      }
    }

    >.errorMessage {
      color: $danger;
      font-size: 0.75em;
      font-weight: 500;
      margin-top: 4px;
    }

    >.alert {
      margin-top: 8px;
      font-style: italic;

      &.alert--danger {
        color: $danger;
      }
    }
  }

  &.mutilLocale {
    >.wraper {
      margin-bottom: 7px;

      .input {
        display: flex;
        min-height: 38px;

        .localeLabel {
          font-size: 0.9em;
          background: rgba($color: $dark, $alpha: 0.05);
          border-right: 1px solid $borderColor;
          color: $grey;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: max-content;
          padding: 0 7px;
          text-transform: uppercase;
        }
      }
    }

    >.generalErrorMessage {
      color: $danger;
      font-size: 0.75em;
      font-weight: 500;
    }
  }

  &.error {
    >.wraper {
      >.input {
        border: 1px solid $danger !important;
      }
    }

    >.label {
      color: $danger;
    }
  }

  &.alert {
    margin-bottom: 8px;
  }

  &:focus-within {

    // border-color: $primary;
    >.label {
      color: $primary;
    }

    >.wraper {
      >.input {

        // border-color: #4680FF !important;
        // transition: 0.5s;
        // -webkit-transition: 0.5s;
        // -moz-transition: 0.5s;
        // -ms-transition: 0.5s;
        // -o-transition: 0.5s;
        input:not(.react-datetime-picker__inputGroup__input, .react-date-picker__inputGroup__input) {
          border: 1px solid rgba(255, 255, 255, 0.75);
          box-shadow: 0px 0px 4px rgb(255 255 255);
        }
      }

      >.generalErrorMessage,
      >.errorMessage {
        display: none;
      }
    }
  }

  &.disabled {
    >.wraper {
      .input {
        border-color: transparent !important;
        background: rgba(255, 255, 255, 0.1);
        // opacity: 0.5;
        // border-color: rgba($color: #000000, $alpha: 0);
        user-select: none;
        position: relative;

        &:after {
          cursor: default;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          // background: rgba($color: #000000, $alpha: 0.05);
          border-radius: $inputRadius;
        }

        .label {
          background: none;
        }

        input {
          box-shadow: none;
          border: none;
          background: transparent;
          color: rgba(255, 255, 255, 0.5);
        }

        // ============================ Related style ================================
        .InputSelect__single-value--is-disabled,
        .InputSelectAsync__single-value--is-disabled {
          color: $dark;
        }

        .react-datetime-picker__clear-button,
        .react-datetime-picker__calendar-button,
        .react-date-picker__clear-button,
        .react-date-picker__calendar-button,
        .InputSelect__indicators,
        .InputSelectAsync__indicators {
          display: none;
        }

        // ============================ End Related style ============================
      }
    }
  }
}